import { insurance } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

/**
 * @deprecated use v2 instead
 */
export function getInsurancePolicy({ url: { service }, token }: IAPI) {
  return async function getInsurancePolicy(id: string) {
    const url = new URL(`/insurance/${id}`, service);
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (!response.ok) {
      throw new Error(`Network response was not OK: ${response.status}`);
    }
    return (await response.json()) as insurance.InsurancePolicy;
  };
}
